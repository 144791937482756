import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Panels: Added a new type of panel.*`}</li>
      <li parentName="ul">{`Bug Fix: Bugs related to the unsubscribe function have been fixed.`}</li>
      <li parentName="ul">{`Bug Fix: Email image Preview available.`}</li>
      <li parentName="ul">{`Bug Fix: Aggregations by team, skill or channel list the correct names on the aggregation table.`}</li>
      <li parentName="ul">{`Bug Fix: Load more items when putting an app in full screen.`}</li>
      <li parentName="ul">{`Bug Fix: #0 Metrics on V4 showing up #.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      